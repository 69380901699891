<template>
  <v-bottom-sheet v-model="sheet" inset max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <ListIcon
          ><v-icon>{{ icon }}</v-icon></ListIcon
        >
        <v-list-item-content>
          <v-list-item-title
            >{{ value.code }}
            <template v-if="subtitle"
              >({{ subtitle }})</template
            ></v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-card tile>
      <v-system-bar window color="success"
        >Klasse<v-spacer></v-spacer
        ><v-btn @click="sheet = false" icon small
          ><v-icon small>mdi-close</v-icon></v-btn
        ></v-system-bar
      >

      <v-card-text class="title pt-8 pb-0 text-center">
        {{ value.code }}
      </v-card-text>
      <v-card-text class="subtitle pt-0 text-center">
        {{ value.studentCount.total }} Schüler:innen
      </v-card-text>
      <v-card-text class="display text-center">
        <v-avatar color="success">
          <v-icon color="white">{{ icon }}</v-icon>
        </v-avatar>
      </v-card-text>

      <v-list>
        <v-list-item
          @click="sheet = false"
          :to="{ name: 'Timetable', params: { code: value.code } }"
        >
          <ListIcon><v-icon>mdi-calendar-clock</v-icon></ListIcon>
          <v-list-item-content>
            <v-list-item-title> Stundenplan anzeigen </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="sheet = false"
          :to="{ name: 'SchoolClassRegister', params: { id: value.id } }"
        >
          <ListIcon><v-icon>mdi-book</v-icon></ListIcon>
          <v-list-item-content>
            <v-list-item-title> Klassenbuch anzeigen </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="sheet = false"
          :to="{ name: 'SchoolClassList', params: { id: value.id } }"
        >
          <ListIcon><v-icon>mdi-view-list</v-icon></ListIcon>
          <v-list-item-content>
            <v-list-item-title> Liste anzeigen </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="sheet = false"
          :to="{ name: 'Courses', query: { search: value.code } }"
        >
          <ListIcon><v-icon>mdi-school</v-icon></ListIcon>
          <v-list-item-content>
            <v-list-item-title> Kurse anzeigen </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-card-actions>
        <v-btn @click="sheet = false" text block>schliessen</v-btn>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
export default {
  name: "SchoolClassItem",
  props: {
    value: null,
    icon: null,
  },
  data() {
    return { sheet: false };
  },
  computed: {
    subtitle() {
      if (!this.value) return "";
      let result = "";
      if (this.value.teacher) {
        result = this.value.teacher.code;
      }
      if (this.value.substituteTeacher) {
        if (result.length) result += " ";
        result += `(${this.value.substituteTeacher.code})`;
      }
      if (this.value.studentCount) {
        if (result.length) result += ", ";
        result += this.value.studentCount.total;
      }
      return result;
    },
  },
};
</script>
